<template>
    <form
        name="rendez-vous"
        action=""
        method="post"
        accept-charset="UTF-8"
        @submit="sendForm($event)"
    >
        <input type="hidden" name="action" value="contact-form/send" />

        <label for="clinique">{{ $t('Clinique') }}</label>
        <select id="clinique" name="clinique" v-model="formData.clinique" required>
            <option value="">{{ $t('Choisir') }}</option>
            <option :value="item.title" v-for="(item, i) in cliniquesItems" :key="i">
                {{ item.title }}
            </option>
        </select>

        <label for="traitements">{{ $t('Traitements') }}</label>
        <select id="traitements" name="traitements" v-model="formData.traitements" required>
            <option value="">{{ $t('Choisir') }}</option>
            <option :value="item.title" v-for="(item, i) in traitementsItems" :key="i">
                {{ item.title }}
            </option>
        </select>

        <label for="name">{{ $t('Prénom et nom') }}</label>
        <input type="text" id="name" name="nom" v-model="formData.nom" required />

        <label for="email">{{ $t('Courriel') }}</label>
        <input type="email" id="email" name="courriel" v-model="formData.courriel" required />

        <label for="telephone">{{ $t('Téléphone') }}</label>
        <input type="text" id="telephone" name="telephone" v-model="formData.telephone" required />

        <div class="row-2">
            <div class="input">
                <label for="date">{{ $t('Date désirée') }}</label>
                <input type="date" id="date" name="date" v-model="formData.date" :min="todayDateFormatted" required />
            </div>
            <div class="input">
                <label for="heure">{{ $t('Heure désirée') }}</label>
                <select id="traitements" name="heure" v-model="formData.heure" required>
                    <option value="">{{ $t('Choisir') }}</option>
                    <option value="09h00">09h00</option>
                    <option value="10h00">10h00</option>
                    <option value="11h00">11h00</option>
                    <option value="12h00">12h00</option>
                    <option value="13h00">13h00</option>
                    <option value="14h00">14h00</option>
                    <option value="15h00">15h00</option>
                    <option value="16h00">16h00</option>
                    <option value="17h00">17h00</option>
                    <option value="18h00">18h00</option>
                    <option value="19h00">19h00</option>
                    <option value="20h00">20h00</option>
                </select>
            </div>
        </div>

        <input class="site-btn alternate" type="submit" :value="$t('Envoyer')" />
    </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import axios from 'axios'
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'

export default {
    name: 'FormRendezVous',

    props: {
        hideform: { type: Function, required: true },
    },

    data() {
        return {
            isSending: false,
            formData: {
                clinique: '',
                traitements: '',
                heure: '',
            },
        }
    },

    computed: {
        todayDateFormatted() {
            return new Date().toISOString().split('T')[0]
        },
        traitementsItems() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.traitementsItems.data
        },
        cliniquesItems() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.cliniquesItems.data
        },
        ...mapState(['globalsIsLoaded']),
        ...mapGetters(['globals']),
    },

    methods: {
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            this.formData['formName'] = 'Rendez-vous'
            this.formData['sujet'] = 'Rendez-vous'

            const formData = new FormData()
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)
            formData.append('courriel', this.formData.courriel)
            formData.append('nom', this.formData.nom)
            formData.append('clinique', this.formData.clinique)
            formData.append('traitements', this.formData.traitements)
            formData.append('date', this.formData.date)
            formData.append('heure', this.formData.heure)
            formData.append('telephone', this.formData.telephone)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(`${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`, formData, config)
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre message a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    this.hideform()
                    this.formData = {}
                    this.formMessage = ''

                    setTimeout(() => {
                        this.isSending = false
                    }, 3000)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.hideform()
                        this.formData = {}
                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
