<template>
    <div id="app">
        <nav-bar />
        <main>
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </main>
        <footer-bar />

        <side-panel></side-panel>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import SidePanel from '@/components/SidePanel.vue'

export default {
    computed: {
        ...mapState(['globalsIsLoaded']),
        ...mapGetters(['seo']),
    },

    components: {
        NavBar,
        FooterBar,
        SidePanel,
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    watch: {
        globalsIsLoaded: {
            handler(globalsIsLoaded) {
                if (globalsIsLoaded) {

                    if (window.location.href.includes('https://votredentisterie.com')) {
                        // redirect
                        window.location.href = window.location.href.replace('https://votredentisterie.com', 'https://www.votredentisterie.com')
                    }

                    setTimeout(() => {
                        const topAlert = document.querySelector('.alert-bar')
                        const topNav = document.querySelector('.top-nav')
                        const mainNav = document.querySelector('.main-nav')

                        document.querySelector('#app').style.paddingTop = `${
                            topNav.offsetHeight + mainNav.offsetHeight + topAlert.offsetHeight
                        }px`

                        window.addEventListener(
                            'scroll',
                            () => {
                                const top = window.scrollY

                                if (top >= topNav.offsetHeight + topAlert.offsetHeight) {
                                    document.querySelector('body').classList.add('nav-is-fixed')
                                    return
                                }

                                document.querySelector('body').classList.remove('nav-is-fixed')
                            },
                            false
                        )
                    }, 155)
                }
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: scale(1.0125) rotate(0.525deg);
}
</style>
