import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/cliniques',
        name: 'CliniquesIndex-fr',
        component: () =>
            import(/* webpackChunkName: "cliniques" */ '@/views/cliniques/CliniquesIndex.vue'),
        meta: { lang: 'fr', json: 'cliniques' },
    },
    {
        path: '/en/clinics',
        name: 'CliniquesIndex-en',
        component: () =>
            import(/* webpackChunkName: "cliniques" */ '@/views/cliniques/CliniquesIndex.vue'),
        meta: { lang: 'en', json: 'cliniques' },
    },
    {
        path: '/cliniques/:slug',
        name: 'CliniquesEntry-fr',
        component: () =>
            import(/* webpackChunkName: "clinique" */ '@/views/cliniques/CliniquesEntry.vue'),
        meta: { lang: 'fr', json: 'cliniquesItems', slug: true },
    },
    {
        path: '/en/clinics/:slug',
        name: 'CliniquesEntry-en',
        component: () =>
            import(/* webpackChunkName: "clinique" */ '@/views/cliniques/CliniquesEntry.vue'),
        meta: { lang: 'en', json: 'cliniquesItems', slug: true },
    },
    {
        path: '/soins-dentaires',
        name: 'TraitementsIndex-fr',
        component: () =>
            import(
                /* webpackChunkName: "traitements" */ '@/views/traitements/TraitementsIndex.vue'
            ),
        meta: { lang: 'fr', json: 'traitements' },
    },
    {
        path: '/en/dental-care',
        name: 'TraitementsIndex-en',
        component: () =>
            import(
                /* webpackChunkName: "traitements" */ '@/views/traitements/TraitementsIndex.vue'
            ),
        meta: { lang: 'en', json: 'traitements' },
    },
    {
        path: '/soins-dentaires/:slug',
        name: 'TraitementsEntry-fr',
        component: () =>
            import(/* webpackChunkName: "traitement" */ '@/views/traitements/TraitementsEntry.vue'),
        meta: { lang: 'fr', json: 'traitementsItems', slug: true },
    },
    {
        path: '/en/dental-care/:slug',
        name: 'TraitementsEntry-en',
        component: () =>
            import(/* webpackChunkName: "traitement" */ '@/views/traitements/TraitementsEntry.vue'),
        meta: { lang: 'en', json: 'traitementsItems', slug: true },
    },
    {
        path: '/ressources',
        name: 'RessourcesIndex-fr',
        component: () =>
            import(/* webpackChunkName: "ressources" */ '@/views/ressources/RessourcesIndex.vue'),
        meta: { lang: 'fr', json: 'ressources' },
    },
    {
        path: '/en/resources',
        name: 'RessourcesIndex-en',
        component: () =>
            import(/* webpackChunkName: "ressources" */ '@/views/ressources/RessourcesIndex.vue'),
        meta: { lang: 'en', json: 'ressources' },
    },
    {
        path: '/ressources/type/:category',
        name: 'RessourcesCategory-fr',
        component: () =>
            import(/* webpackChunkName: "ressources" */ '@/views/ressources/RessourcesIndex.vue'),
        meta: { lang: 'fr', json: 'ressources' },
    },
    {
        path: '/en/resources/type/:category',
        name: 'RessourcesCategory-en',
        component: () =>
            import(/* webpackChunkName: "ressources" */ '@/views/ressources/RessourcesIndex.vue'),
        meta: { lang: 'en', json: 'ressources' },
    },
    {
        path: '/ressources/:slug',
        name: 'RessourcesEntry-fr',
        component: () =>
            import(/* webpackChunkName: "ressource" */ '@/views/ressources/RessourcesEntry.vue'),
        meta: { lang: 'fr', json: 'ressourcesItems', slug: true },
    },
    {
        path: '/en/resources/:slug',
        name: 'RessourcesEntry-en',
        component: () =>
            import(/* webpackChunkName: "ressource" */ '@/views/ressources/RessourcesEntry.vue'),
        meta: { lang: 'en', json: 'ressourcesItems', slug: true },
    },
    {
        path: '/a-propos',
        name: 'About-fr',
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/About.vue'),
        meta: { lang: 'fr', json: 'aboutUs' },
    },
    {
        path: '/en/about-us',
        name: 'About-en',
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/About.vue'),
        meta: { lang: 'en', json: 'aboutUs' },
    },
    {
        path: '/carrieres',
        name: 'Careers-fr',
        component: () => import(/* webpackChunkName: "carrieres" */ '@/views/Careers.vue'),
        meta: { lang: 'fr', json: 'carrieres' },
    },
    {
        path: '/en/careers',
        name: 'Careers-en',
        component: () => import(/* webpackChunkName: "carrieres" */ '@/views/Careers.vue'),
        meta: { lang: 'en', json: 'carrieres' },
    },
    {
        path: '/dentiste/:slug',
        name: 'TeamsEntry-fr',
        component: () =>
            import(/* webpackChunkName: "equipeMembresEntry" */ '@/views/teams/TeamsEntry.vue'),
        meta: { lang: 'fr', json: 'equipeMembres', slug: true },
    },
    {
        path: '/en/dentist/:slug',
        name: 'TeamsEntry-en',
        component: () =>
            import(/* webpackChunkName: "equipeMembresEntry" */ '@/views/teams/TeamsEntry.vue'),
        meta: { lang: 'en', json: 'equipeMembres', slug: true },
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 500)

    next()
})

export default router
