<template>
    <div class="side-panel" :class="{ showpanel: panelIsOpen }">
        <div
            class="inner-panel"
            :class="{ showpanel: panelIsOpen && panelIsOpen === 'rendez-vous' }"
        >
            <div class="panel">
                <p class="font-black">{{ $t('Demande de rendez-vous') }}</p>
                <p class="small-text">{{ "Une demande de rendez-vous sera envoyée à la clinique de votre choix. Nous vous contacterons afin de confirmer la date et l'heure choisie." }}</p>
                <br />

                <FormRendezVous :hideform="hideForm" />

                <img
                    @click.prevent="togglePanel(!panelIsOpen)"
                    src="@/assets/img/close.svg"
                    class="close"
                    alt="close"
                />
            </div>
        </div>

        <div class="inner-panel" :class="{ showpanel: panelIsOpen && panelIsOpen === 'job' }">
            <div class="panel">
                <p class="font-black">{{ $t('Postuler') }}</p>
                <br />

                <FormJob :hideform="hideForm" />

                <img
                    @click.prevent="togglePanel(!panelIsOpen)"
                    src="@/assets/img/close.svg"
                    class="close"
                    alt="close"
                />
            </div>
        </div>

        <div class="overlay" @click.prevent="togglePanel(!panelIsOpen)"></div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormRendezVous from '@/components/FormRendezVous.vue'
import FormJob from '@/components/FormJob.vue'

export default {
    components: {
        FormRendezVous,
        FormJob,
    },

    methods: {
        hideForm() {
            this.togglePanel(!this.panelIsOpen)
        },
        ...mapActions(['togglePanel']),
    },

    computed: {
        currentLang() {
            return this.$i18n.locale
        },
        ...mapState(['panelIsOpen']),
    },
}
</script>

<style lang="scss" scoped></style>
