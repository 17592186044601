<template>
    <form
        name="postuler"
        action=""
        method="post"
        accept-charset="UTF-8"
        @submit="sendForm($event)"
        enctype="multipart/form-data"
    >
        <input type="hidden" name="action" value="contact-form/send" />

        <label for="name">{{ $t('Prénom et nom') }}</label>
        <input type="text" id="name" name="name" v-model="formData.nom" required />

        <label for="email">{{ $t('Courriel') }}</label>
        <input type="email" id="email" name="email" v-model="formData.courriel" required />

        <label for="telephone">{{ $t('Téléphone') }}</label>
        <input type="text" id="telephone" name="telephone" v-model="formData.telephone" required />

        <label for="poste">{{ $t('Poste') }}</label>
        <input type="text" id="poste" name="poste" v-model="formData.poste" />

        <label for="file">{{ $t('C.V.') }}</label>
        <div class="input-file">
            <input
                id="file"
                type="file"
                name="file"
                class="inputfile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, text/plain, .pdf"
                @change="uploadFile($event)"
            />
        </div>
        <p class="helper" v-if="formData.file">
            <small>{{ formData.file }}</small>
        </p>

        <label for="message">{{ $t('Message') }}</label>
        <textarea id="message" name="message" v-model="formData.message"></textarea>

        <input class="site-btn alternate" type="submit" :value="$t('Envoyer')" />
    </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import axios from 'axios'
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'

export default {
    name: 'FormJob',

    props: {
        hideform: { type: Function, required: true },
    },

    data() {
        return {
            isSending: false,
            formData: {},
        }
    },

    computed: {
        traitementsItems() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.traitementsItems.data
        },
        cliniquesItems() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.cliniquesItems.data
        },
        ...mapState(['globalsIsLoaded']),
        ...mapGetters(['globals']),
    },

    methods: {
        async uploadFile(event) {
            const fileName = event.target.files[0].name
            this.formData.file = fileName
            this.formData = { ...this.formData }

            // Handling file
            this.formData.attachment = event.target.files[0]
        },
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            this.formData['formName'] = 'Postuler'
            this.formData['sujet'] = 'Postuler'

            const formData = new FormData()
            formData.append('attachment', this.formData.attachment)
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)
            formData.append('poste', this.formData.poste)
            formData.append('courriel', this.formData.courriel)
            formData.append('message', this.formData.message)
            formData.append('nom', this.formData.nom)
            formData.append('telephone', this.formData.telephone)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre message a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    this.hideform()
                    this.formData = {}
                    this.formMessage = ''

                    setTimeout(() => {
                        this.isSending = false
                    }, 3000)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.hideform()
                        this.formData = {}
                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
