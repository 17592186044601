import { render, staticRenderFns } from "./FormRendezVous.vue?vue&type=template&id=639d6562&scoped=true&"
import script from "./FormRendezVous.vue?vue&type=script&lang=js&"
export * from "./FormRendezVous.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639d6562",
  null
  
)

export default component.exports